import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/axios'

import auth from './auth'
import discounts from './discounts'
import notifications from './notifications'
import attendants from './attendants'
import maintenances from './maintenances'
import properties from './properties'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        settings: null,
        isLoading: false,
        lang: 'ar',
        showSideMenu: true
    },

    getters: {
        settings: state => state.settings,
        isLoading: state => state.isLoading,
        lang: state => state.lang,
        showSideMenu: state => state.showSideMenu
    },

    mutations: {
        SET_SETTINGS: (state, payload) => state.settings = payload,
        SET_IS_LOADING: (state, payload) => state.isLoading = payload,
        TOGGLE_SIDE_MENU: state => state.showSideMenu = !state.showSideMenu
    },

    actions: {
        init({ dispatch }) {
            console.log('init function')
            dispatch('fetchSettings');
            dispatch('discounts/fetchDiscounts')
            dispatch('notifications/fetchNotifications')
            dispatch('attendants/fetchAttendants')
            dispatch('maintenances/fetchMaintenances')
            dispatch('properties/fetchProperties')
        },

        async fetchSettings({ commit }) {
            let { data } = await axios.get('settings')
            commit('SET_SETTINGS', data.settings)
            console.log('settings', data)
        }
    },

    modules: {
        auth,
        discounts,
        notifications,
        attendants,
        maintenances,
        properties
    }
})