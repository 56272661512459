import axios from '@/axios'

export default {
    namespaced: true,

    state: {
        attendants: [],
        selectedAttendant: null,
        searchTerm: null
    },

    getters: {
        attendants: state => state.attendants,
        selectedAttendant: state => state.selectedAttendant,
        selectedAttendants: state => {
            return state.attendants.filter(m => m.selected)
        },
    },

    mutations: {
        SET_ATTENDANTS: (state, payload) => state.attendants = payload,
        SET_SELECTED_ATTENDANT: (state, payload) => state.selectedAttendant = payload,
        SELECT_ALL: state => {
            state.attendants.forEach(m => m.selected = true)
        },
        DESELECT_ALL: state => {
            state.attendants.forEach(m => m.selected = false)
        },
        SELECT_ATTENDANT: (state, payload) => {
            state.attendants[state.attendants.indexOf(payload)].selected = true
        },

        DESELECT_ATTENDANT: (state, payload) => {
            state.attendants[state.attendants.indexOf(payload)].selected = false
        },
    },

    actions: {
        async fetchAttendants({ commit }) {
            let { data } = await axios.get('user/attendants')
            data.attendants.forEach(m => m.selected = false)
            commit('SET_ATTENDANTS', data.attendants)

        },

        toggleAttendantsSelection({ commit, getters, state }) {
            if (getters.selectedAttendants.length < state.attendants.length) {
                commit('SELECT_ALL')
            } else {
                commit('DESELECT_ALL')
            }
        },

        toggleAttendantSelection({ commit, state }, payload) {
            let currentState = state.attendants[state.attendants.indexOf(payload)].selected
            if (currentState) {
                commit('SELECT_ATTENDANT', payload)
            } else {
                commit('DESELECT_ATTENDANT', payload)
            }
        },

        async deleteSelectedAttendants({ getters, dispatch }) {
            let ids = []
            getters.selectedAttendants.forEach(doc => ids.push(doc.id))
            let { data } = await axios.delete('user/attendants', { params: { ids } })
            dispatch('fetchAttendants')
            return data
        },

        async saveAttendant({ commit, dispatch, getters, rootState }, payload) {
            if (rootState.auth.user.attendants.length >= 5) {
                return { success: false, message: 'لا يمكنك إضافة أكثر من 5 مستفيدين' }
            }
            let url = getters.selectedAttendant ? `user/attendants/${getters.selectedAttendant.id}` : `user/attendants`
            payload.role = 'user'
            let { data } = await axios({
                method: getters.selectedAttendant ? 'put' : 'post',
                url,
                data: payload
            })

            if (data.success) {
                dispatch('fetchAttendants')
                commit('SET_SELECTED_ATTENDANT', data.attendant)
            }

            return data
        },

        async saveAttendantImage({ getters, dispatch }, image) {
            let formData = new FormData()
            formData.append('images[0]', image)
            formData.append('user_id', getters.selectedAttendant.id)
            let { data } = await axios.post('images', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })

            if (data.success) {
                dispatch('fetchAttendants')
            }

            return data
        },
    }
}