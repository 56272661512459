import Vue from 'vue'
import VueRouter from 'vue-router'

import GuestLayout from '@/layouts/guest'
import MainLayout from '@/layouts/main'
import DocEditLayout from '@/layouts/doc-edit'
Vue.component('guest-layout', GuestLayout)
Vue.component('main-layout', MainLayout)
Vue.component('doc-edit-layout', DocEditLayout)

import Home from '../views/user/home'
import Lease from '../views/user/lease'
import Discounts from '../views/user/discounts'
import Notifications from '../views/user/notifications'
import Attendants from '../views/user/attendants'
import Login from '../views/user/auth/login'
// import Register from '../views/user/auth/register'
import VerifyEmail from '../views/user/auth/verify-email'
import ForgotPassword from '../views/user/auth/forgot-password'
import ResetPassword from '../views/user/auth/reset-password'
import Maintenance from '../views/user/maintenance'

import auth from '@/store/auth'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        component: Home,
        meta: {
            isAuth: true,
        }
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {
            isAuth: true,
        }
    },
    {
        path: '/lease',
        name: 'lease',
        component: Lease,
        meta: {
            isAuth: true,
        }
    },
    {
        path: '/discounts',
        name: 'discounts',
        component: Discounts,
        meta: {
            isAuth: true,
        }
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: Notifications,
        meta: {
            isAuth: true,
        }
    },
    {
        path: '/attendants',
        name: 'attendants',
        component: Attendants,
        meta: {
            isAuth: true,
        }
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        component: Maintenance,
        meta: {
            isAuth: true,
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            isGuest: true,
            layout: 'guest-layout'
        }
    },
    // {
    //     path: '/register',
    //     name: 'register',
    //     component: Register,
    //     meta: {
    //         isGuest: true,
    //         layout: 'guest-layout'
    //     }
    // },
    {
        path: '/verify-email/:email/:token',
        name: 'verifyEmail',
        component: VerifyEmail,
        meta: {
            // isGuest: true,
            layout: 'guest-layout'
        }
    },
    {
        path: '/forgot-password',
        name: 'forgotPassword',
        component: ForgotPassword,
        meta: {
            isGuest: true,
            layout: 'guest-layout'
        }
    },
    {
        path: '/reset-password/:email/:token',
        name: 'resetPassword',
        component: ResetPassword,
        meta: {
            isGuest: true,
            layout: 'guest-layout'
        }
    },
    // {
    //     path: '/checkout',
    //     name: 'checkout',
    //     component: Checkout,
    //     meta: {
    //         isAuth: true,
    //     }
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.isAuth)) {
        if (auth.state.token === null || auth.state.user === null) {
            next({
                name: 'login',
                query: { redirect: to.fullPath }
            })
        } else {
            next()
        }
    } else if (to.matched.some(record => record.meta.isGuest)) {
        if (auth.state.token !== null || auth.state.user !== null) {
            next({
                name: 'home',
            })
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router