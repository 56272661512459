<template>
  <div>
    <div v-if="!user.isAttendant" class="text-right mb-3">
      <button class="btn btn-primary btn-sm" @click="addAttendantTap">
        <fa icon="plus" class="ml-2" />
        <span>إضافة مستفيد</span>
      </button>
    </div>
    <PanelList
      :items="attendants"
      title="المستفيدون"
      :isAllSelected="isAllSelected"
      @toggle-items-selection="toggleAttendantsSelection()"
      :canSelectAll="false"
    >
      <AttendantPanelItem
        v-for="(attendant, i) in attendants"
        :key="i"
        :attendant="attendant"
      />
    </PanelList>

    <AttendantModal />
  </div>
</template>

<script>
import PanelList from "@/components/general/panel-list";
import AttendantPanelItem from "@/components/panel-items/attendant";
import AttendantModal from "@/components/modals/attendant";
import AttendantsMixin from '@/mixins/attendants'
import AuthMixin from '@/mixins/auth'

export default {
  mixins: [AttendantsMixin, AuthMixin],

  methods: {
    addAttendantTap () {
      this.selectAttendant(null)
      this.$bvModal.show('attendant-modal')
    }
  },

  components: {
    PanelList,
    AttendantPanelItem,
    AttendantModal,
  },
};
</script>