import { mapActions, mapMutations, mapGetters } from 'vuex';

export default {
    methods: {
        ...mapActions({}),

        ...mapMutations({
            selectProperty: 'properties/SELECT_PROPERTY'
        })
    },

    computed: {
        ...mapGetters({
            properties: 'properties/properties',
            selectedProperty: 'properties/selectedProperty'
        })
    }
}