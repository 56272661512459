<template>
  <div id="side-menu-wrapper">
    <aside>
      <ul class="list-unstyled">
        <li>
          <router-link
            :to="{ name: 'home' }"
            :class="{ active: $router.name == 'home' }"
            title="بياناتي"
          >
            <fa icon="id-card" />
            <span>بياناتي</span>
          </router-link>
        </li>

        <li>
          <router-link
            :to="{ name: 'lease' }"
            :class="{ active: $router.name == 'lease' }"
            title="العقد"
          >
            <fa icon="file-signature" />
            <span>العقد</span>
          </router-link>
        </li>

        <li>
          <router-link
            :to="{ name: 'discounts' }"
            :class="{ active: $router.name == 'discounts' }"
            title="الخصومات"
          >
            <fa icon="tags" />
            <span>الخصومات</span>
          </router-link>
        </li>

        <li>
          <router-link
            :to="{ name: 'notifications' }"
            :class="{ active: $router.name == 'notifications' }"
            title="الإشعارات"
          >
            <fa icon="bell" />
            <span>الإشعارات</span>
            <div v-if="user.notificationsCounter > 0" class="badge badge-danger float-left">{{ user.notificationsCounter }}</div>
          </router-link>
        </li>

        <li>
          <router-link
            :to="{ name: 'attendants' }"
            :class="{ active: $router.name == 'attendants' }"
            title="المستفيدون"
          >
            <fa icon="users" />
            <span>المستفيدون</span>
          </router-link>
        </li>

        <li>
          <router-link
            :to="{ name: 'maintenance' }"
            :class="{ active: $router.name == 'maintenance' }"
            title="الطلبات"
          >
            <fa icon="tools" />
            <span>الطلبات</span>
          </router-link>
        </li>
      </ul>

      <a href="#!" @click="logoutTap" title="تسجيل الخروج" id="side-menu-logout">
        <fa icon="sign-out-alt" style="font-size: 24px" />
        <span>تسجيل الخروج</span>
      </a>
    </aside>
  </div>
</template>

<script>
import AuthMixin from '@/mixins/auth'

export default {
  mixins: [AuthMixin],

  methods: {
    logoutTap() {
      this.logout().then(() => {
        this.$router.push({ name: "login" });
      });
    },
  },

  components: {
  },
};
</script>